import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
import Navbar from "../components/Navbar";
import { Navigation } from "swiper/modules";
import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Accordion from "react-bootstrap/Accordion";
// import Contact from "../components/Contact";
import Footer from "../components/footer";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "../components/hero_section_css/hero_section.css";
import image1 from "../assets/images/hero/1.webp";
import image2 from "../assets/images/hero/2.webp";
import image5 from "../assets/images/hero/5.webp";
import group from "../assets/images/Group.svg";
import Frame29 from "../assets/images/Frame29.svg";
import amit from "../assets/images/testimonials/amit.png";
import asif from "../assets/images/testimonials/asif.png";
import sarah from "../assets/images/testimonials/sarah.png";
import michael from "../assets/images/testimonials/michael.png";
import linda from "../assets/images/testimonials/linda.png";
import david from "../assets/images/testimonials/david.png";
import jenny from "../assets/images/testimonials/jenny.png";
import james from "../assets/images/testimonials/james.png";
import "swiper/css/scrollbar";

import "./style.css";
import bf1 from "../assets/images/beforeafter/beforeafter2.webp";
import bf2 from "../assets/images/beforeafter/beforeafter3.webp";

import floor from "../assets/images/products/floor.jpeg";
import kitchen from "../assets/images/products/kitchen.jpg";
import bathroom from "../assets/images/products/bathroom.webp";
import wall from "../assets/images/products/wall.png";
import Frame61 from "../assets/images/Frame61.svg";
import logo75 from "../assets/images/logos/mohawk.svg";
import logo54 from "../assets/images/logos/pergo.svg";
import logo49 from "../assets/images/logos/armstrong.svg";

import "swiper/css";
import "swiper/css/scrollbar";

import tile from "../assets/images/services/tile.jpg";
import living from "../assets/images/services/livingremo.jpg";
import carpet from "../assets/images/services/carpet.jpg";
import homeoffice from "../assets/images/services/homeoff1.jpg";
import bathroomRemo from "../assets/images/services/bathroomremo.jpg";
import paint from "../assets/images/services/paint.jpg";
import kitchenRemo from "../assets/images/services/kitchenremo.jpg";
import floorRemo from "../assets/images/services/floor.jpg";
import cabinet from "../assets/images/services/cabinet.jpg";
import lighting from "../assets/images/services/lighting.jpg";

const Home = () => {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    startDate: "",
    endDate: "",
    service: "",
    description: "",
    email: "",
    phone: "",
    to_name: "Inam S.",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (value.trim()) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phone: value });
    if (value.trim()) {
      setErrors((prevErrors) => ({ ...prevErrors, phone: "" }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let newErrors = {};

    // Validate description
    if (!formData.description.trim()) {
      newErrors.description = "Description is required.";
    }

    // Validate email
    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    // Validate phone
    if (!formData.phone.trim()) {
      newErrors.phone = "Phone number is required.";
    }

    setErrors(newErrors);

    // If there are validation errors, do not proceed
    if (Object.keys(newErrors).length > 0) return;

    const serviceId = "service_b0wt45f";
    const templateId = "template_b323xdg";
    const userId = "pIzOkduz4r5CAb_pQ";

    const templateParams = {
      to_name: formData.to_name,
      from_name: formData.email,
      message: `
        Service: ${formData.service}
        Start Date: ${formData.startDate}
        End Date: ${formData.endDate}
        Phone: ${formData.phone}
        Description: ${formData.description}
      `,
    };

    emailjs.send(serviceId, templateId, templateParams, userId).then(
      (response) => {
        console.log(formData);
        console.log("Email sent successfully!", response);
        alert("Your request has been sent successfully!");
        // Clear form fields and errors after successful submission
        setFormData({
          startDate: "",
          endDate: "",
          service: "",
          description: "",
          email: "",
          phone: "",
          to_name: "Inam S.",
        });
        setErrors({});
      },
      (error) => {
        console.error("Failed to send email.", error);
        alert("An error occurred. Please try again.");
      }
    );
  };

  const productsRef = useRef(null);
  const contactRef = useRef(null);

  const scrollToProducts = () => {
    if (productsRef.current) {
      productsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const testimonials = [
    {
      text: "The team handled everything from design to execution flawlessly. Our outdoor patio looks incredible, and it’s now our favorite place to entertain.",
      name: "Jenny W.",
      position: "Small Business Owner",
      image: jenny,
    },
    {
      text: "Project was completed under budget and in a timely manner! Mr. Inam and his team went above and beyond to accomodate our needs.",
      name: "Asif K.",
      position: "Homeowner",
      image: asif,
    },
    {
      text: "Our bathroom remodel was seamless from start to finish. The team communicated every step of the way, and the results are simply stunning.",
      name: "Michael L.",
      position: "Property Manager",
      image: michael,
    },
    {
      text: "I had a major event coming up, and they re-tiled my hall in record time without compromising on quality. The results were fantastic, and my guests were impressed!",
      name: "Amit P.",
      position: "Event Hall",
      image: amit,
    },
    {
      text: "We are beyond thrilled with our newly remodeled kitchen! The team’s attention to detail and dedication to quality made all the difference.",
      name: "Sarah J.",
      position: "Homeowner",
      image: sarah,
    },

    {
      text: "We had new hardwood floors installed throughout the house, and the quality of work is outstanding. The installation was quick and hassle-free.",
      name: "Linda P.",
      position: "Interior Designer",
      image: linda,
    },
    {
      text: "I was amazed at how efficient and professional the crew was. The entire basement was transformed into a family-friendly space in record time.",
      name: "David T.",
      position: "Homeowner",
      image: david,
    },

    {
      text: "They turned our dated living room into a modern, cozy space that perfectly suits our style. The team was respectful of our home and left the place spotless every day.",
      name: "James K.",
      position: "Architect",
      image: james,
    },
  ];
  const services = [
    {
      title: "Full Kitchen Remodeling",
      description:
        "Revamp your culinary space with a modern, functional design that inspires.",
      startingPrice: "$5,000",
      image: kitchenRemo,
    },
    {
      title: "Bathroom Remodeling",
      description:
        "Upgrade to a luxurious bathroom with stylish fixtures and optimized space.",
      startingPrice: "$1,200",
      image: bathroomRemo,
    },
    {
      title: "Living Room Transformation",
      description:
        "Create a cozy, elegant space for relaxation and entertainment.",
      startingPrice: "$2,500",
      image: living,
    },
    {
      title: "Home Office Buildouts",
      description:
        "Design the perfect work-from-home environment tailored to productivity.",
      startingPrice: "$1,500",
      image: homeoffice,
    },
    {
      title: "Flooring Installation",
      description:
        "Choose from hardwood, vinyl, laminate, and tile for durable, stunning floors.",
      startingPrice: "$1,000",
      image: floorRemo,
    },
    {
      title: "Custom Tile Design & Installation",
      description:
        "Bring personality to your space with custom tile designs for floors, walls, or backsplashes.",
      startingPrice: "$800",
      image: tile,
    },
    {
      title: "Carpet Installation",
      description: "Elevate comfort and style with premium carpet options.",
      startingPrice: "$1,200",
      image: carpet,
    },
    {
      title: "Interior Painting & Finishing",
      description:
        "Refresh your home’s interior with high-quality paint and finish options.",
      startingPrice: "$500",
      image: paint,
    },
    {
      title: "Lighting Installation & Upgrade",
      description:
        "Brighten up your home with custom lighting solutions that enhance ambiance.",
      startingPrice: "$600",
      image: lighting,
    },
    {
      title: "Cabinetry & Storage Solutions",
      description:
        "Add elegance and functionality with custom cabinets and smart storage options.",
      startingPrice: "$1,200",
      image: cabinet,
    },
  ];

  return (
    <div>
      <Container>
        <Navbar />{" "}
        <div className="mb-3">
          <Swiper
            spaceBetween={0}
            slidesPerView={2}
            loop={true}
            pagination={true}
            modules={[Pagination]}
            className="mySwiper"
          >
            <SwiperSlide className="p-2">
              <img src={image1} />
            </SwiperSlide>
            <SwiperSlide className="p-2">
              <img src={image2} />
            </SwiperSlide>
            <SwiperSlide className="p-2">
              <img src={image5} />
            </SwiperSlide>
          </Swiper>{" "}
          <h5 className="mt-5 hero_section_h4 ">
            Your Vision, Our Craftsmanship – Unmatched Remodeling, Tile, and
            Carpet Solutions Since 2000
          </h5>
          <button
            className="hero_section_button py-2 px-4 mt-3"
            onClick={scrollToProducts}
          >
            See All Services <i class="bi bi-arrow-right"></i>
          </button>
        </div>
        <div>
          <div className="section_2_our_client_bar mt-5">
            <h4>Our Project</h4>
            {/* <h5>See all</h5> */}
          </div>
          <p className="section3_p adjust-margin ">
            Discover how we bring unique visions to life through our
            meticulously crafted interior designs.
          </p>
          <div className="mb-3">
            <Swiper
              slidesPerView={1}
              modules={[Navigation]}
              navigation={{
                prevEl: ".prev_section_3",
                nextEl: ".next_section_3",
              }}
              className="mySwiper_three"
            >
              <SwiperSlide className="w-100">
                <div class="card swiper_section2_cards">
                  <div class="card-body text-start">
                    {/* <h5 class="card-title">Special title treatment</h5> */}
                    <img src={bf1} />
                    <p class="card-text section3_swiper p-0 m-0 mt-1">
                      Kitchen Before and After
                    </p>
                    <div></div>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide
                slidesPerView={1}
                className="w-100"
                modules={[Navigation]}
                navigation={{
                  prevEl: ".prev_section_3",
                  nextEl: ".next_section_3",
                }}
              >
                <div class="card swiper_section2_cards">
                  <div class="card-body text-start">
                    {/* <h5 class="card-title">Special title treatment</h5> */}
                    <img src={bf2} />
                    <p class="card-text section3_swiper p-0 m-0 mt-1">
                      Kitchen Before and After
                    </p>
                    <div></div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
            <Container>
              <div className="d-flex justify-content-between">
                <h4 style={{ fontSize: "16px" }}>
                  Kitchen, Floor, Lighting and More!
                </h4>
                <div className="d-flex" style={{ gap: "10px" }}>
                  <div className="prev_section_3">
                    <img src={bf2} className="pre_btn" />
                  </div>
                  <div className="next_section_3">
                    <img src={bf2} className="next_btn" />
                  </div>
                </div>
              </div>
            </Container>
          </div>

          <Container className="mb-3">
            <div className="section_2_our_client_bar mt-5">
              <h4>Our Services</h4>
              {/* <h5>See all</h5> */}
            </div>
            <p className="section3_p  adjust-margin">
              Discover how we bring unique visions to life through our
              meticulously crafted interior designs.
            </p>
            <Swiper
              slidesPerView={2}
              modules={[Navigation]}
              className="w-100 mySwiper_four"
            >
              {services.map((service, index) => (
                <SwiperSlide className="w-75 p-2" key={index}>
                  <div className="card swiper_section2_cards">
                    <div className="card-body p-2 text-start">
                      <img
                        src={service.image}
                        className="swiper_max_img"
                        alt={`${service.title} Image`}
                      />
                      <h5 className="main_text_card4 mt-2">{service.title}</h5>
                      <p className="card-text section3_swiper p-0 m-0">
                        {service.description}
                      </p>
                      <h6 className="amount mt-2">
                        Starting from <span>{service.startingPrice}</span>
                      </h6>
                      <button
                        className="request_btn_swiper_4 py-2"
                        onClick={scrollToContact}
                      >
                        Get a Free Quote <i className="bi bi-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </Container>
          <div className="mb-3" ref={productsRef}>
            <div className="section_2_our_client_bar mt-5">
              <h4>Products We Offer</h4>
              {/* <h5>See all</h5> */}
            </div>
            <p className="section3_p  ml-5 adjust-margin">
              Meticulously crafted products showcasing our dedication to
              precision, durability, and timeless aesthetics.
            </p>
            <Container>
              <Row className="align-items-stretch">
                <Col xs={12} sm={6} md={6} lg={6} className="d-flex">
                  <div className="product_card p-2 my-1 flex-grow-1 d-flex flex-column">
                    <img src={floor} className="w-100" alt="Flooring" />
                    <h4 className="m-0 mt-2 p-0 main_text_card4">Flooring</h4>
                    <h5 className="m-0 mt-2 p-0 main_text_card4">
                      Carpet, hardwood, laminate, vinyl, tile, and natural stone
                      options.
                    </h5>
                    <div
                      className="d-flex mt-2"
                      style={{ alignItems: "center", gap: "5px" }}
                    >
                      <h5 className="m-0 p-0">Starting from $30</h5>
                      <p className="m-0 p-0">per square foot</p>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} className="d-flex">
                  <div className="product_card p-2 my-1 flex-grow-1 d-flex flex-column">
                    <img src={kitchen} className="w-100" alt="Countertops" />
                    <h4 className="m-0 mt-2 p-0 main_text_card4">
                      Countertops
                    </h4>
                    <h5 className="m-0 mt-2 p-0 main_text_card4">
                      Granite, quartz, marble, butcher block, and laminate
                      countertops.
                    </h5>
                    <div
                      className="d-flex mt-2"
                      style={{ alignItems: "center", gap: "5px" }}
                    >
                      <h5 className="m-0 p-0">Starting from $70</h5>
                      <p className="m-0 p-0">per square foot</p>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} className="d-flex">
                  <div className="product_card p-2 my-1 flex-grow-1 d-flex flex-column">
                    <img src={bathroom} className="w-100" alt="Fixtures" />
                    <h4 className="m-0 mt-2 p-0 main_text_card4">Fixtures</h4>
                    <h5 className="m-0 mt-2 p-0 main_text_card4">
                      Bathroom sinks, tubs, showers, faucets, and kitchen
                      fixtures.
                    </h5>
                    <div
                      className="d-flex mt-2"
                      style={{ alignItems: "center", gap: "5px" }}
                    >
                      <h5 className="m-0 p-0">Starting from $200</h5>
                      <p className="m-0 p-0">per fixture</p>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} className="d-flex">
                  <div className="product_card p-2 my-1 flex-grow-1 d-flex flex-column">
                    <img
                      src={wall}
                      className="w-100"
                      alt="Paint & Wall Coverings"
                    />
                    <h4 className="m-0 mt-2 p-0 main_text_card4">
                      Paint & Wall Coverings
                    </h4>
                    <h5 className="m-0 mt-2 p-0 main_text_card4">
                      Interior/exterior paint, wallpaper, and wall textures.
                    </h5>
                    <div
                      className="d-flex mt-2"
                      style={{ alignItems: "center", gap: "5px" }}
                    >
                      <h5 className="m-0 p-0">Starting from $1,200</h5>
                      <p className="m-0 p-0">per project</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <Container>
            <div className="section_2_our_client_bar mt-5">
              <h4 className="about_h4">Our Previous Work</h4>
              {/* <h5>See all</h5> */}
            </div>
            <p className="section3_p adjust-margin">
              Our portfolio showcases enduring designs that stand the test of
              time, both in style and function.
            </p>
            <img src={Frame61} className="w-100" />
          </Container>
          <Container className="pb-4 pt-4">
            <h4 className="about_h4 mt-4">About Us</h4>
            <p className="section3_p ">
              Since 2000, Apex Carpet, Tile & Remodeling has been transforming
              homes across the Greater Houston area. With over two decades of
              experience, we specialize in high-quality carpet, tile, flooring,
              and remodeling solutions tailored to bring your vision to life.
              From modern kitchen makeovers to luxurious bathroom upgrades,
              we’ve helped countless homeowners enhance the beauty,
              functionality, and comfort of their spaces. At Apex, we pride
              ourselves on craftsmanship, attention to detail, and a commitment
              to delivering exceptional results that exceed expectations. Your
              home is our passion, and your satisfaction is our priority.
            </p>
          </Container>

          <Container className="pb-4 w-100">
            <h4 className="mt-3 about_h4">Our Mission</h4>
            <Row>
              <Col xs="6" className="p-0">
                <div className="text-center m-1 p-2 mision_border_main">
                  <img src={group} />
                  <p className="transforming">
                    Crafting Spaces, Elevating Experiences
                  </p>
                </div>
              </Col>
              <Col xs="6" className="p-0">
                <div className="text-center m-1 p-2 mision_border_main">
                  <img src={group} />
                  <p className="transforming">
                    Innovative Designs, Lasting Impressions
                  </p>
                </div>
              </Col>
              <Col xs="6" className="p-0">
                <div className="text-center m-1 p-2 mision_border_main">
                  <img src={group} />
                  <p className="transforming">
                    Turning Houses into Dream Homes
                  </p>
                </div>
              </Col>
              <Col xs="6" className="p-0">
                <div className="text-center m-1 p-2 mision_border_main">
                  <img src={group} />
                  <p className="transforming">
                    Quality Craftsmanship, Timeless Results
                  </p>
                </div>
              </Col>
            </Row>
          </Container>

          <Container className="pb-4 pt-4">
            <h4 className="mt-4 about_h4">Clients Say of Us</h4>
            <p className="section3_p ">
              Read the inspiring stories of our clients and see how we’ve helped
              turn their design dreams into reality.
            </p>

            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              className="mySwiperrr"
            >
              {testimonials.map((testimonial, index) => (
                <SwiperSlide key={index} className="width_45 border p-2">
                  <div className="w-100">
                    <img src={Frame29} style={{ width: "100px " }} />
                    <p className="section3_swiper section_our_mission">
                      {testimonial.text}
                    </p>
                    <div className="d-flex gap-sm-3 gap-2">
                      <img
                        src={testimonial.image}
                        className="swiper_section2_img"
                      />
                      <div className="card_flex_area">
                        <h5>{testimonial.name}</h5>
                        <p>{testimonial.position}</p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </Container>
          <div>
            <h4 className="about_h4 mt-4" ref={contactRef}>
              Contact Us
            </h4>
            <p className="section3_p ">
              Get in touch with us today to discuss your project and discover
              how we can assist you.
            </p>

            <div className="contact_us_form mt-3 p-2 mb-5">
              <form onSubmit={handleSubmit}>
                <div
                  className="d-flex justify-content-between"
                  style={{ gap: "5px" }}
                >
                  <div className="mb-3 h-100 w-50">
                    <label className="form-label">Start Date</label>
                    <input
                      type="date"
                      name="startDate"
                      className="form-control w-100"
                      value={formData.startDate}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3 h-100 w-50">
                    <label className="form-label">End Date</label>
                    <input
                      type="date"
                      name="endDate"
                      className="form-control w-100"
                      value={formData.endDate}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label className="form-label">Services</label>
                  <select
                    className="w-100 form-control"
                    name="service"
                    value={formData.service}
                    onChange={handleInputChange}
                  >
                    <option value="">Select a Service</option>
                    <option value="Flooring">Flooring</option>
                    <option value="Countertops">Countertops</option>
                    <option value="Remodeling">Remodeling</option>
                    <option value="Paint">Paint</option>
                  </select>
                </div>

                <div className="mb-3">
                  <label className="form-label">Description</label>
                  <textarea
                    name="description"
                    className={`form-control ${
                      errors.description ? "is-invalid" : ""
                    }`}
                    placeholder="Describe your vision and any questions you have for us."
                    rows="3"
                    value={formData.description}
                    onChange={handleInputChange}
                  ></textarea>
                  {errors.description && (
                    <div className="invalid-feedback">{errors.description}</div>
                  )}
                </div>

                <div className="mb-3">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    name="email"
                    className={`form-control ${
                      errors.email ? "is-invalid" : ""
                    }`}
                    placeholder="abc@xyz.com"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  {errors.email && (
                    <div className="invalid-feedback">{errors.email}</div>
                  )}
                </div>

                <div className="mb-3">
                  <label className="form-label">Contact NO.</label>
                  <PhoneInput
                    international
                    name="phone"
                    placeholder="Phone number"
                    value={formData.phone}
                    onChange={handlePhoneChange}
                    className={`w-100 py-2 px-2 ${
                      errors.phone ? "is-invalid" : ""
                    }`}
                  />
                  {errors.phone && (
                    <div className="invalid-feedback">{errors.phone}</div>
                  )}
                </div>

                <button
                  type="submit"
                  className="btn w-100"
                  style={{
                    background: "#6CA6D1",
                    borderRadius: "10px",
                    color: "white",
                  }}
                >
                  Request Quote
                </button>
              </form>
            </div>
            <Container>
              <Row>
                <Col xs="6" className="p-0 m-0">
                  <iframe
                    title="Apex Tile, Carpet & Remodeling Location"
                    width="100%"
                    height="100%"
                    src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=4551%20Hwy%206%20N,%20Houston,%20TX%2077084+(Apex%20Tile,%20Carpet%20&amp;%20Remodeling)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                    allowFullScreen
                  />
                </Col>
                <Col xs="6">
                  <div>
                    <h6 className="px-0 m-0">Business hours</h6>
                    <div className="business mt-2"></div>

                    <Row>
                      <Col className="mt-1" xs="4">
                        <h6 className="m-0 p-0 days">Monday-</h6>
                      </Col>
                      <Col className="mt-1" xs="8">
                        <p className="m-0 p-0 time text-center">
                          9:00 AM - 7:00 PM
                        </p>
                      </Col>
                      <Col className="mt-1" xs="4">
                        <h6 className="m-0 p-0 days">Tuesday-</h6>
                      </Col>
                      <Col className="mt-1" xs="8">
                        <p className="m-0 p-0 time text-center">
                          9:00 AM - 7:00 PM
                        </p>
                      </Col>
                      <Col className="mt-1" xs="4">
                        <h6 className="m-0 p-0 days-wed">Wednesday-</h6>
                      </Col>
                      <Col className="mt-1" xs="8">
                        <p className="m-0 p-0 time text-center">
                          9:00 AM - 7:00 PM
                        </p>
                      </Col>
                      <Col className="mt-1" xs="4">
                        <h6 className="m-0 p-0 days">Thursday-</h6>
                      </Col>
                      <Col className="mt-1" xs="8">
                        <p className="m-0 p-0 time text-center">
                          9:00 AM - 7:00 PM
                        </p>
                      </Col>
                      <Col className="mt-1" xs="4">
                        <h6 className="m-0 p-0 days">Friday-</h6>
                      </Col>
                      <Col className="mt-1" xs="8">
                        <p className="m-0 p-0 time text-center">
                          9:00 AM - 7:00 PM
                        </p>
                      </Col>
                      <Col className="mt-1" xs="4">
                        <h6 className="m-0 p-0 days">Saturday-</h6>
                      </Col>
                      <Col className="mt-1" xs="8">
                        <p className="m-0 p-0 time text-center">
                          9:00 AM - 7:00 PM
                        </p>
                      </Col>
                      <Col className="mt-1" xs="4">
                        <h6 className="m-0 p-0 days">Sunday-</h6>
                      </Col>
                      <Col className="mt-1" xs="8">
                        <p
                          className="m-0 p-0 days text-center"
                          style={{ color: "red" }}
                        >
                          Closed
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mt-1" xs="4">
                        <h6 className="m-0 p-0 days" style={{ color: "blue" }}>
                          Phone
                        </h6>
                      </Col>
                      <Col className="mt-1" xs="8">
                        <p className="m-0 p-0 days text-center">
                          <a
                            href="tel:+1-281-550-6300"
                            style={{ textDecoration: "none", color: "inherit" }}
                          >
                            281-550-6300
                          </a>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="pt-4">
            <h4 className="about_h4 mt-4 mb-4 text-center companies">
              {" "}
              Some of The Companies We Represent
            </h4>
            <Container>
              <Row>
                <Col xs="4" className="p-1">
                  <img
                    src={logo75}
                    className="h-75 w-100 border px-3 py-2"
                    style={{ borderRadius: "5px" }}
                  />
                </Col>
                <Col xs="4" className="p-1">
                  <img
                    src={logo54}
                    className="h-75 w-100 border px-3 py-2"
                    style={{ borderRadius: "5px" }}
                  />
                </Col>
                <Col xs="4" className="p-1">
                  <img
                    src={logo49}
                    className="h-75 w-100 border px-3 py-2"
                    style={{ borderRadius: "5px" }}
                  />
                </Col>
              </Row>
            </Container>
          </div>
          <div>
            <h4 className="FAQS mt-3">Frequently Asked Questions</h4>
            <p>
              Find answers to some of the most common questions about our
              remodeling services.
            </p>

            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  How long does a typical remodeling project take?
                </Accordion.Header>
                <Accordion.Body>
                  The duration of a remodeling project depends on the scope and
                  complexity. For example, a bathroom remodel might take 2–3
                  weeks, while a full kitchen renovation could take 4–6 weeks.
                  We'll provide a detailed timeline during your project
                  consultation.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Do you offer free estimates?
                </Accordion.Header>
                <Accordion.Body>
                  Yes, we offer free in-home estimates. Our team will assess
                  your space, discuss your vision, and provide a detailed
                  estimate to help you make an informed decision.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  What types of materials do you use?
                </Accordion.Header>
                <Accordion.Body>
                  We work with high-quality materials from trusted suppliers.
                  Whether you’re interested in luxury finishes or cost-effective
                  options, we can recommend materials that fit your style and
                  budget.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  Will I be able to stay in my home during the remodeling
                  process?
                </Accordion.Header>
                <Accordion.Body>
                  It depends on the extent of the remodel. For smaller projects,
                  you can usually remain in your home with minimal disruption.
                  However, larger projects like a full kitchen remodel may
                  require temporary accommodations. We’ll work with you to
                  minimize inconvenience.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  Do you handle all necessary permits?
                </Accordion.Header>
                <Accordion.Body>
                  Yes, we handle all required permits and inspections for your
                  remodeling project. Our team ensures compliance with local
                  regulations so you can have peace of mind.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  How do you ensure the project stays within budget?
                </Accordion.Header>
                <Accordion.Body>
                  During the planning phase, we provide a detailed estimate
                  based on your goals and budget. We maintain transparent
                  communication throughout the project to avoid unexpected costs
                  and adjust as needed with your approval.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  Can I make changes to the design during the remodeling
                  process?
                </Accordion.Header>
                <Accordion.Body>
                  We encourage all design changes to be finalized before the
                  project begins. However, if you need to make adjustments, our
                  team will work with you to accommodate them where possible,
                  and we’ll discuss any potential impact on the budget and
                  timeline.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  Do you offer warranties on your work?
                </Accordion.Header>
                <Accordion.Body>
                  Yes, we stand behind our work with a warranty. We offer a
                  one-year warranty on workmanship, and the manufacturers’
                  warranties cover materials. Our goal is to ensure your
                  satisfaction and peace of mind long after the project is
                  complete.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  What payment options do you offer?
                </Accordion.Header>
                <Accordion.Body>
                  We accept various payment options, including credit cards,
                  checks and cryptocurrency. We’ll discuss payment terms and
                  schedules during your initial consultation.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header>
                  How do I get started with my remodeling project?
                </Accordion.Header>
                <Accordion.Body>
                  Simply reach out to us via phone or our website to schedule a
                  consultation. We’ll discuss your vision, answer any questions,
                  and guide you through each step to bring your project to life.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default Home;
