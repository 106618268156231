import React from "react";
import logo2 from "../assets/images/logo2.svg";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
const Footer = () => {
  return (
    <div>
      <hr />
      <div className="d-flex ">
        <Container>
          <Row>
            <Col xs="3" style={{ alignContent: "center" }}>
              <img
                src={logo2}
                className="w-100"
                style={{ maxHeight: "100px" }}
              />
            </Col>
            <Col xs="9">
              <p className="text-start">
                Bringing Houston’s unique styles to life for over 20 years –
                your home, your vision, our expertise.{" "}
              </p>
            </Col>
            <Col xs="12">
              <Row className="d-flex justify-content-between text-start">
                <div>
                  {" "}
                  © 2025 APEX CARPET, TILE AND REMODELING, ALL RIGHTS RESERVED.
                </div>
                {/* <Col xs="4" className="list_item">
                  <h6>Home</h6>
                </Col>
                <Col xs="4" className="list_item">
                  <h6>Services</h6>
                </Col>
                <Col xs="4" className="list_item">
                  <h6>Products</h6>
                </Col>
                <Col xs="4" className="list_item">
                  <h6>About Us</h6>
                </Col>
                <Col xs="4" className="list_item">
                  <h6>Contact</h6>
                </Col>
                <Col xs="4" className="list_item">
                  <h6>FAQ</h6>
                </Col> */}
              </Row>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
