import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import logo from "../assets/images/logo2.svg";

const Navbar = ({ sections }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const scrollToSection = (ref) => {
    handleClose();
    if (ref && ref.current) {
      const yOffset = -10; // Adjust for fixed header height if needed
      const yPosition =
        ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: yPosition, behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="navbar_section py-3">
        {/* <Button variant="" className="border px-2 py-0" onClick={handleShow}>
          <i className="bi bi-list fs-2"></i>
        </Button> */}
        <h4 className="text-center">Apex Carpet, Tile & Remodeling</h4>
        <img src={logo} alt="Apex Remodeling Logo" />
      </div>

      {/* <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Navigate</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="list-unstyled">
            {sections.map((section, index) => (
              <li key={index}>
                <button
                  className="btn btn-link text-decoration-none"
                  onClick={() => scrollToSection(section.ref)}
                >
                  {section.label}
                </button>
              </li>
            ))}
          </ul>
        </Offcanvas.Body>
      </Offcanvas> */}
    </>
  );
};

export default Navbar;
